<template>
    <v-row no-gutters style="contain: content; height:inherit">
      <!-- Map View -->
      <v-col cols="12" md="12" sm="12" style="height: inherit">
        <v-card class="mapLocal">
          <!-- Map -->
          <gmap-map
            ref="mapRef"
            key="mapKey"
            id="map"
            :center="center"
            :zoom="zoom"
            class="gMap"
            map-type-id="terrain"
            style="width: inherit; height: inherit; z-index: 1"
            :options="{
                scrollwheel: true,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: true,
                fullscreenControl: false,
            }"
          >
            <!-- Markers -->
            <gmap-marker
              v-if="POItoView"
              :position="{
                lat: center.lat,
                lng: center.lng,
              }"
            />
          </gmap-map>
        </v-card>
  
        <!-- ADD POI Information Card -->
          <v-card 
            key="poiKey" 
            style="background-color: var(--v-greyRaised-base) !important; position: absolute; overflow-y: scroll; 
                   overflow-x:hidden; border-radius: 16px; height: 70vh; width: 17vw; top: 8vh; right: 1vw; z-index: 2000 !important; contain:content;"
            >
            <v-card-subtitle class="d-flex justify-center">
              Add Point of Interest
            </v-card-subtitle>
            <v-card-text>
              <v-row id="locationName">
              <v-text-field
                placeholder="Enter Location Name"
                hint="Autocompleted by Google Search"
                outlined
                dense
                style="margin: 1.5vh 0.5vw 0.2vh 0.5vw"
                @change="checkRequired"
                label="Location Name*"
                persistent-placeholder
                v-model="placeName"
                prepend-inner-icon="edit_note"
              /></v-row>
              <v-text-field
                outlined
                dense
                style="margin: 1.5vh 0.5vw 0.2vh 0.5vw"
                @change="checkRequired"
                label="Friendly Name*"
                persistent-placeholder
                v-model="friendlyName"
                prepend-inner-icon="edit_note"
              />
              <v-row id="functionality">
              <v-select
                outlined
                dense
                hide-selected
                multiple=""
                small-chips
                deletable-chips
                counter="3"
                @change="checkRequired('function')"
                placeholder="Select Function"
                persistent-placeholder
                label="Function*"
                prepend-inner-icon="warehouse"
                style="margin: -1vh 0.5vw 0.2vh 0.5vw;  "
                v-model="selectedFunction"
                :items="locationTypes"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select> </v-row>
              <v-row id="organisationName">
              <v-autocomplete
                style="margin: -1vh 0.5vw 0.2vh 0.5vw"
                persistent-placeholder
                placeholder="Search for an organisation"
                outlined
                dense
                hide-no-data
                clearable
                return-object
                @change="checkRequired"
                label="Organisation*"
                v-model="selectedOrganisation"
                prepend-inner-icon="business"
                :loading="searchingOrganisations"
                :search-input.sync="searchOrganisations"
                :menu-props="{ closeOnContentClick: true }"
                :items="organisations.data"
                @input="getFboCodes"
                item-text="name"
                item-value="id"
              > 
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              </v-row>
              <v-row id="alias">
              <v-text-field
                outlined
                :key="aliasKey"
                style="margin: -1vh 0.1vw 0.8vh 0.5vw"
                dense
                persistent-hint
                readonly
                clearable
                persistent-placeholder
                v-model="aliases[0].alias"
                placeholder="Add an alternative name"
                label="Alias"
                prepend-inner-icon="new_label"
                class="activityPoint"
                :loading="loadingAliases"
              >
                <template v-slot:append-outer>
                  <v-tooltip top open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click="openAlias"
                        v-on="on"
                        style="bottom: 0.7vh"
                      >
                        <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                          >add_circle_outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Add an Alternative Name</span>
                  </v-tooltip>
                </template>
              </v-text-field> </v-row>
              <v-row id="activity-point">
              <v-text-field
                outlined
                style="margin: -1vh 0.1vw 0.8vh 0.5vw"
                dense
                persistent-hint
                readonly
                clearable
                @change="checkRequired"
                v-model="selectedAP.code"
                :hint="selectedAP.name"
                label="Activity-Point"
                prepend-inner-icon="domain_add"
                class="activityPoint"
                :loading="loadingAps"
                :disabled="!selectedOrganisation"
              >
                <template v-slot:append-outer>
                  <v-tooltip top open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        @click="addActivityPoint"
                        v-on="on"
                        style="bottom: 0.7vh"
                      >
                        <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                          >add_circle_outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Add Activity Point</span>
                  </v-tooltip>
                </template>
              </v-text-field> </v-row>
              <v-row id="fbo-code">
              <v-text-field
                outlined
                dense
                persistent-hint
                readonly
                clearable
                @change="checkRequired"
                @click:clear="clearFboSelected"
                v-model="selectedFbo.fboCode"
                :hint="selectedFbo.fboName"
                label="FBO-Code"
                prepend-inner-icon="tag"
                style="margin: -1vh 0.1vw 0.8vh 0.5vw"
                :loading="loadingFboCodes"
                :disabled="!selectedOrganisation"
                :error="fboCodeUsed"
                :error-messages="fboCodeUsed ? 'FBO-Code already in use' : ''"
              >
                <template v-slot:append-outer>
                  <v-tooltip top open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn  icon @click="addFbo" v-on="on" style="bottom: 0.7vh">
                        <v-icon color="edit" size="30" style="margin-right: 0.1vw"
                          >add_circle_outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Add FBO-Code</span>
                  </v-tooltip>
                </template>
              </v-text-field> </v-row>
              
              <v-divider style="margin: 0 0 1.5vh 0"></v-divider>
              <v-row id="locationGeoInfo">
                <v-col cols="12" md="12" sm="12" >
                  <v-autocomplete
  
                    persistent-placeholder
                    placeholder="Search for the nearest Town/City"
                    outlined
                    dense
                    hide-no-data
                    clearable
                    return-object
                    label="Town/City*"
                    v-model="selectedLocode"
                    :loading="searchingLocodes"
                    :search-input.sync="searchLocodes"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="locodes.data"
                    item-text="name"
                    item-value="id"
                  >     
                  <template v-slot:selection="data">
                       <span> {{ data.item.name }} - <b>{{ data.item.locode }}</b></span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }} - <b>{{ item.locode }}</b></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                    <v-autocomplete
                      ref="countrySelect"
                      class="countryAuto"
                      outlined
                      hide-details
                      dense
                      :items="countries"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ closeOnContentClick: true }"
                      return-object
                      v-model="selectedCountry"
                      :loading="loadingCountry"
                    >
                      <template v-slot:selection="data">
                        <v-avatar size="32" v-if="data.item">
                          <v-img
                            v-if="data.item.iso2"
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        <span v-if="data.item" class="ml-2">
                          {{ data.item.iso2 }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img
                            contain
                            v-if="data.item.iso2"
                            :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                  <v-text-field
                    persistent-placeholder
                    outlined
                    @change="checkRequired"
                    dense
                    label="Province"
                    v-model="province"
                  />
                  </v-col>                                                  
                  <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0" >
                  <v-text-field
                    persistent-placeholder
                    readonly
                    outlined
                    hint="Autocompleted by Google Search"
                    dense
                    @change="checkRequired"
                    label="Latitude*"
                    v-model="chosenLatitude"
                  />
                </v-col>
                  <v-col cols="12" md="6" sm="6" style="margin: -3.5vh 0 0 0">
                  <v-text-field
                    persistent-placeholder
                    outlined
                    readonly
                    @change="checkRequired"
                    dense
                    label="Longitude*"
                    v-model="chosenLongitude"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <span style="position: absolute; font-style:italic; font-size:small; opacity: 70%; bottom: 1vh; left: 1vh">* required fields</span>
  
              <!-- Save Button -->
              <v-btn
              v-if="editingPoi"
              :disabled="updateDisabled"
              class="infoSave"
              color="edit"
              @click="updatePoi"
              style="width: 5vw"
            >
              <v-progress-circular
                v-if="updateLoading"
                indeterminate
                color="white"
                size="20"
                icon
              />
              <v-icon v-if="!updateLoading" size="25">save</v-icon
              ></v-btn>
          </v-card>
  
  
        <!-- FBO Dialog Card -->
        <v-dialog v-model="fboCard" max-width="650">
          <v-card height="425" style="overflow-y: hidden">
            <v-toolbar dark
              >Get FBO-Code
              <v-spacer></v-spacer>
              <v-btn icon @click="addFbo"
                ><v-icon large color="primary">close</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="fboSearchAll"
                    :loading="searchingFbos"
                    clearable
                    class="dataSearch"
                    append-icon="search"
                    placeholder="Search FBO-Code or Organisation"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-card style="overflow-y: auto; overflow-x: hidden">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-list height="300">
                      <v-list-item
                        :loading="searchingFbos"
                        three-line
                        outlined
                        dense
                        v-for="item in moreFboCodes.data"
                        :key="item.id"
                        @click="selectFbo(item)"
                      >
                        <v-list-item-avatar>
                          <v-icon left>business</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.fboCode }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.fboName }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ item.fboType }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
  
        <!-- Activity Point Dialog -->
        <v-dialog v-model="activityPointCard" max-width="650">
          <v-card height="425" style="overflow-y: hidden">
            <v-toolbar dark
              >Get Activity-Point
              <v-spacer></v-spacer>
              <v-btn icon @click="addActivityPoint"
                ><v-icon large color="primary">close</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="acitvityPointSearch"
                    :loading="searchingActivityPoints"
                    clearable
                    class="dataSearch"
                    append-icon="search"
                    placeholder="Search Activity Point or Office"
                    
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-card style="overflow-y: auto; overflow-x: hidden">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-list height="300">
                      <v-list-item
                        :loading="searchingActivityPoints"
                        two-line
                        outlined
                        dense
                        v-for="item in moreActivities.data"
                        :key="item.id"
                        @click="selectActivityPoint(item)"
                      >
                        <v-list-item-avatar>
                          <v-icon left>business</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.name }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.code }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
  
        <!-- Alias Card -->
        <v-dialog v-model="aliasCard" max-width="650">
          <v-card height="550" style="overflow-y: hidden">
            <v-toolbar style="height: 10vh" dark>
              <span style="margin: 0 0 -4vh 0; font-size: larger"><p> Add an Alias <br /></p>
               <span style="font-size: small; font-style:italic; opacity: 0.6; line-height: 0.2 "><p>This is because there are multiple names for the same places.<br/></p>
                 <p>ex. Cape Town Container Terminal is also known as the Ben Schoeman Container Terminal</p></span></span>
              <v-spacer></v-spacer>
              <v-btn icon @click="openAlias"
                ><v-icon large color="primary">close</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="12" sm="12" style="display: grid; justify-items: center" >
                  <v-text-field
                    outlined
                    dense
                    label="Alias"
                    persistent-placeholder
                    v-model="aliasToAdd.alias"
                    clearable
                    class="aliasAddBar"
                    placeholder="Add an Alternative Name for this POI"
                    @keyup.enter="addAlias"
                  >
                  </v-text-field>
                  <v-autocomplete
                    style="width: 18vw; margin: -1vh 0 -1.5vh 0;"
                    persistent-placeholder
                    placeholder="What organisation uses this Alias?"
                    outlined
                    dense
                    hide-no-data
                    clearable
                    return-object
                    label="Associated Organisation"
                    v-model="aliasToAdd.aliasOrganisation"
                    prepend-inner-icon="business"
                    :loading="searchingAliasOrganisations"
                    :search-input.sync="searchAliasOrganisations"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="aliasOrganisations.data"
                    item-text="name"
                    item-value="id"
                  > 
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-btn @click="addAlias" :disabled="aliasDisabled" color="success">
                    Accept
                  </v-btn>
                </v-col>
              </v-row>
              <v-card flat style="overflow-y: auto; overflow-x: hidden">
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-list height="300" v-if="aliases[0].alias != null">
                      <v-list-item
                        two-line
                        outlined
                        dense
                        v-for="item in aliases"
                        :key="item.alias"
                      >
                        <v-list-item-avatar>
                          <v-icon left>new_label</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ item.alias }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.aliasType }}
                          </v-list-item-subtitle>
  
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="removeAlias(item)">
                              <v-icon color="error">delete</v-icon>
                            </v-btn>
                          </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import { getGoogleMapsAPI } from "gmap-vue";
  
  
  export default {
    props: [
    'POItoView'
    ],
    components: {

    },
    data: () => ({
      fboCodes: [],
      aliases: [
        {
          alias: null,
          aliasOrganisation: null
        }
      ],
      moreFboCodes: [],
      moreActivities: [],
      selectedOrganisation: null,
      selectedLocode: null,
      selectedFbo: {},
      selectedAP: {},
      chosenPoi:[],
      countries: [],
      selectedPoi: null,
      selectedFunction: null,
      existingAliases: [],
      friendlyName: null,

      aliasToAdd: {
        alias: null,
        aliasOrganisation: null,
      },
      deletedAliases: [],
      aliasType: null,
      functionLimit: 3,
      applyToAll: false,
      place: null,
      zoomLevel: 10,
      placeholderKey: 0,
      mapKey: 0,
      poiKey: 0,
      aliasKey: 0,
      poiLimit: 20,
      fboList: 0,
      actPointList: 0,
      autocompleteKey: 0,
      customName: "",
      address: "",
      formattedAddress: "",
      province: "",
      placeName: null,
      country: "",
      coordinates: "",
      chosenLatitude: null,
      chosenLongitude: null,
      postalCode: "",
      fboCodeUsed: false,
      editingPoi: false,
      saveDisabled: true,
      fboSearch: null,
      searchingOrganisations: false,
      searchingAliasOrganisations: false,
      searchingLocodes: false,
      loadingCountry: false,
      aliasDisabled: true,
      updateLoading: false,
      updateDisabled: false,
      loadingFboCodes: false,
      loadingAliases: false,
      loadingAps: false,
      saveLoading: false,
      searchingFbos: false,
      searchingPois: false,
      searchingActivityPoints: false,
      searchOrganisations: null,
      searchAliasOrganisations: null,
      searchLocodes: null,
      searchPois: null,
      fboSearchAll: null,
      acitvityPointSearch: null,
      zoom: 11,
      fboCard: false,
      aliasCard: false,
      activityPointCard: false,
      organisationTimeout: null,
      aliasOrgTimeout: null,
      locodeTimeout: null,
      poiSearchTimeout: null,
      fboSearchTimeout: null,
      activitySearchTimeout: null,
      mapCursor: "pointer",
      countrySelected: { country: "za" },
      center: {
        lat: -33.9321,
        lng: 18.8602,
       
      },
      organisations: {
        total: 0,
        data: [],
      },
      aliasOrganisations: {
        total: 0,
        data: [],
      },
      locodes: {
        total: 0,
        data: [],
      },
      selectedCountry: {
        continent: "Africa",
        id: 343,
        name: "South Africa",
        iso2: "ZA",
      },
      params: {
        search: "",
        limit: 'All',
        filter: {
          functionality: null,
          subdivision: null
        }
      },
      locationTypes: [
        "Farm",
        "Pack-House (On-Farm)",
        "Pack-House (Off-Farm)",
        "Dry Storage",
        "Commercial Cold Store",
        "Warehouse",
        "Distribution Center",
        "Drying Facility",
        "Weigh-Bridge",
        "Toll-Gate",
        "Truck-Depot",
        "Port",
        "Port Terminal",
        "Airport Terminal",
        "Exporter",
        "Head-Office",
        "Regional Office",
        "Container Depot",
        "Regulatory Body",
        "Plug-in Depot",
        "Gassing Point",
        "Other",
      ],
      currentMarker: null,
    }),
  
    computed: {
      google: getGoogleMapsAPI,
      filteredSelectedFunctions() {
        return this.selectedFunction.slice(0, this.functionLimit);
      },
    },

    async created() {
        this.countries = await this.$API.searchCountriesBasic({});
    },
  
    watch: {

        'POItoView' : {
            immediate: true,
            handler(val) {
                this.viewMarker(val)
            }
        },
        

        async searchOrganisations(val) {
          if (this.searchingOrganisations) {
            clearTimeout(this.organisationTimeout);
          }
          if (val && val.length > 2) {
            this.organisationTimeout = setTimeout(async () => {
              this.searchingOrganisations = true;
              this.organisations = await this.$API.getOrganisationsPOI({
                params: {
                  search: val,
                  limit: 15,
                },
              });
              this.searchingOrganisations = false;
            }, 150);
            this.searchingOrganisations = false;
          } else {
            clearTimeout(this.organisationTimeout);
            this.searchingOrganisations = false;
          }
        },
      
        async searchAliasOrganisations(val) {
          if (this.searchingAliasOrganisations) {
            clearTimeout(this.aliasOrgTimeout);
          }
          if (val && val.length > 2) {
            this.aliasOrgTimeout = setTimeout(async () => {
              this.searchingAliasOrganisations = true;
              this.aliasOrganisations = await this.$API.getOrganisationsPOI({
                params: {
                  search: val,
                  limit: 15,
                },
              });
              this.searchingAliasOrganisations = false;
            }, 150);
            this.searchingAliasOrganisations = false;
          } else {
            clearTimeout(this.aliasOrgTimeout);
            this.searchingAliasOrganisations = false;
          }
        },
      
        async searchLocodes(val) {
          if (this.searchingLocodes) {
            clearTimeout(this.locodeTimeout);
          }
          if (val && val.length > 2) {
            this.locodeTimeout = setTimeout(async () => {
              this.searchingLocodes = true;
              this.locodes = await this.$API.searchLocodes({
                params: {
                  filter: this.selectedCountry.iso2,
                  search: val,
                  limit: 15,
                },
              });
              this.searchingLocodes = false;
            }, 150);
            this.searchingLocodes = false;
          } else {
            clearTimeout(this.locodeTimeout);
            this.searchingLocodes = false;
          }
        },
      
        'aliasToAdd.alias': {
          handler: function (val) {
            if (val && val.length >= 4) {
              this.aliasDisabled = false;
            } else {
              this.aliasDisabled = true;
            }
          }
        },
      
        async fboSearchAll(val) {
          if (this.searchingFbos) {
            clearTimeout(this.fboSearchTimeout);
          }
          if (val && val.length > 2) {
            this.fboSearchTimeout = setTimeout(async () => {
              this.searchingFbos = true;
              this.moreFboCodes = await this.$API.getFboCodes({
                params: {
                  search: val,
                  limit: 15,
                },
              });
              this.searchingFbos = false;
              this.fboSearchTimeout = null;
              this.fboList++;
            }, 150);
          } else {
            clearTimeout(this.fboSearchTimeout);
          }
        },
      
        async acitvityPointSearch(val) {
          if (this.searchingActivityPoints) {
            clearTimeout(this.activitySearchTimeout);
          }
          if (val && val.length > 2) {
            this.activitySearchTimeout = setTimeout(async () => {
              this.searchingActivityPoints = true;
              this.moreActivities = await this.$API.getActivityPoints({
                params: {
                  search: val,
                  limit: 20,
                },
              });
              this.searchingActivityPoints = false;
              this.activitySearchTimeout = null;
              this.actPointList++;
            }, 150);
          } else {
            clearTimeout(this.activitySearchTimeout);
          }
        },
    },
  
    methods: {
      // POI's //////////////////////////// POI's ///////////////////////////////////////////////// POI's //////////////////////////////////////// POI's /////////////////////////// POI's //////////////

      enforceSelectionLimit() {
        if (this.selectedFunction.length > this.functionLimit) {
          this.selectedFunction = this.selectedFunction.slice(0, this.functionLimit);
        }
      },
  
      checkRequired(val) {
        if(val == 'function') {
          this.enforceSelectionLimit();
        }
        if (
          this.fboCodeUsed == false &&
          this.placeName != null &&
          this.selectedFunction != null &&
          // this.selectedOrganisation != null  &&
          this.chosenLatitude != null &&
          this.chosenLongitude != null
        ) {
          this.saveDisabled = false;
        } else {
          this.saveDisabled = true;
        }
      },
  
      clearPoi() {
        this.selectedPoi = null 
        this.radioKey++
        this.editingPoi= false;
        this.placeName = null;
        this.formattedAddress = null;
        this.postalCode = null;
        this.selectedFunction = null;
        this.chosenLatitude = null;
        this.chosenLongitude = null;
        this.radius = 0;
        this.province = null;
        this.aliases = [{
          alias: null,
          aliasOrganisation: null
        }];
        this.selectedOrganisation = {
          id: null,
          name: null
        }
        this.organisations = {
          total: 0,
          data: []
        }
        this.selectedFbo = {
          id: null,
          fboCode: null,
          fboName: null
        }
        
        this.selectedAP = {
          id: null,
          name: null,
          code: null
        }
  
        this.selectedLocode = {
          id: null,
          locode: null,
          name: null
        }
        this.locodes = {
          total: 0,
          data: []
        }
      },
  
      async checkFboCode(item) {
        if (item != null) {
          let validation = await this.$API.checkFboCode(item.fboCode);
          if(validation.status == false) {
            this.fboCodeUsed = false;
          } else if(validation.status == true) {
            this.fboCodeUsed = true;
          }
        }
        else this.fboCodeUsed = false;
      },
  
      clearFboSelected() {
        this.fboCodeUsed = false;
        this.selectedFbo = {
          id: null,
          fboCode: null,
          fboName: null
        }
      },
      viewMarker(poi) {
      console.log(poi)
      this.chosenPoi = poi;
      this.editingPoi= true;
      this.center = {
        lat: poi.lat,
        lng: poi.lng,
      };
      this.placeName = poi.name
      this.friendlyName = poi.friendlyName
      this.formattedAddress = poi.address
      this.postalCode = poi.postalCode
      this.chosenLatitude = poi.lat
      this.chosenLongitude = poi.lng
      this.country = poi.country
      this.radius = poi.radius
      this.province = poi.subdivision
      if(poi.organisation != null) {
      this.selectedOrganisation = {
        id: poi.organisationId,
        name: poi.organisation.name
      }

      this.organisations = {
        total: 1,
        data: [this.selectedOrganisation]
      }
      } else {
        this.selectedOrganisation = {
          id: null,
          name: null
        }
        this.organisations = {
          total: 0,
          data: []
        }
      }
      if(poi.organisationFbo != null) {
        this.selectedFbo = {
        id: poi.organisationFboId,
        fboCode: poi.fboCode,
        fboName: poi.organisationFbo.fboName
      }
      } else {
        this.selectedFbo = {
        id: null,
        fboCode: null,
        fboName: null
      }
      }
      if(poi.activityPoint != null) {
        this.selectedAP = {
        id: poi.activityPoint.id,
        name: poi.activityPoint.name,
        code: poi.activityPoint.code
      }
      } else {
        this.selectedAP = {
        id: null,
        name: null,
        code: null
      }
      }
      if(poi.unLocode != null){
        this.selectedLocode = {
        id: poi.unLocode.id,
        locode: poi.unLocode.locode,
        name: poi.unLocode.name
      }
      this.locodes = {
        total: 1,
        data: [this.selectedLocode]
      }
      } else {
        this.selectedLocode = {
        id: null,
        locode: null,
        name: null
      }
      this.locodes = {
        total: 0,
        data: []
      }
      }
      if(poi.poiAliases != null && poi.poiAliases.length != 0) {
        this.existingAliases = poi.poiAliases
        this.aliases = poi.poiAliases
      } else {
        this.aliases = [{
          alias: null,
          aliasOrganisation: null
        }]
      }
      if(poi.poiFunctions != null) {
        if(poi.poiFunctions.length > 0){
            this.selectedFunction =[poi.poiFunctions[0].functionality]
          }
          if(poi.poiFunctions.length > 1){
            this.selectedFunction =[poi.poiFunctions[0].functionality, poi.poiFunctions[1].functionality]
          }
          if(poi.poiFunctions.length > 2){
            this.selectedFunction =[poi.poiFunctions[0].functionality, poi.poiFunctions[1].functionality, poi.poiFunctions[2].functionality]
          }
      }
    },
  
      async updatePoi() {
        this.updateLoading = true;
        let data = {
          id: this.chosenPoi.id,
          name: this.placeName,
          friendlyName: this.friendlyName,
          address: this.formattedAddress,
          city:  this.selectedLocode ? this.selectedLocode.name : this.vicinity,
          country: this.selectedCountry.name,
          subdivision: this.province,
          postalCode: this.postalCode,
          lat: this.chosenLatitude,
          lng: this.chosenLongitude,
          radius: this.radius,
          organisationId: this.selectedOrganisation ? this.selectedOrganisation.id : null,
          organisationFboId: this.selectedFbo.id,
          fboCode: this.selectedFbo.fboCode,
          unLocodeId: this.selectedLocode ? this.selectedLocode.id : null,
        };
        let poi = await this.$API.updatePoi(data);                                                                                //Update the POI
        if (this.selectedAP.name && poi) {
          let activityPoint = {
          id: this.selectedAP.id,
          organisationId: this.selectedOrganisation.id,
          poiId: poi.id,
          };
          await this.$API.updateActivityPoint(activityPoint);
        }
        if(this.chosenPoi.poiFunctions.length != 0 && this.selectedFunction != null) {                                            //If the POI has functions and the user has selected functions
          if(this.chosenPoi.poiFunctions.length == this.selectedFunction.length ) {                                               //If the length of the POI's functions are equal to that of 
            for(let i = 0; i < this.selectedFunction.length ; i++) {                                                              //the length of the selected functions, then replace those functions
             let poiFunction = {
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
            }
          }
          else if(this.chosenPoi.poiFunctions.length > this.selectedFunction.length) {                                            
            for(let i = 0; i < this.selectedFunction.length; i++) {                                                               //If the length of the POI's functions are greater than that of
             let poiFunction = {                                                                                                  //the selected functions, then replace them and delete the rest
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
            }
            for(let i = this.selectedFunction.length; i < this.chosenPoi.poiFunctions.length; i++) {
              await this.$API.deletePoiFunction(this.chosenPoi.poiFunctions[i].id);
            }
          }
          else if(this.chosenPoi.poiFunctions.length < this.selectedFunction.length) {                                            //If the length of the POI's functions are less than that of
            for(let i = 0; i < this.chosenPoi.poiFunctions.length; i++) {                                                         //the selected functions, then replace those and create the rest
             let poiFunction = {
                id: this.chosenPoi.poiFunctions[i].id,
                poiId: this.chosenPoi.id,
                functionality: this.selectedFunction[i],
              };
              let result = await this.$API.updatePoiFunction(poiFunction);
            }
            for(let i = this.chosenPoi.poiFunctions.length; i < this.selectedFunction.length; i++) {
              let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: this.selectedFunction[i]});
            }
          }
        }
          else {                                                                                                                //If the POI has no functions and the user has selected functions
            if(this.selectedFunction != null) {                                                                                 //then create those functions
              this.selectedFunction.forEach(async x =>  {
              let result = await this.$API.createPoiFunction({poiId: poi.id, functionality: x})
              x = result;
          })
          }
        }
        if(this.aliases[0].alias != null && this.aliases[0].alias != "") {                                                       //If the user has entered aliases, then create them
          
          this.aliases.forEach(async x => {
            if(x.new) {
              let result = await this.$API.createPoiAlias({poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
              x.id = result.id;
            } 
            else {
              let result = await this.$API.updatePoiAlias({id: x.id, poiId: poi.id, alias: x.alias, aliasType: x.aliasType, organisationId: x.organisationId});
              x = result;
            }
            });
          this.deletedAliases.forEach(async x => {
            if(Object.hasOwn(x, "id")){
                await this.$API.deletePoiAlias(x.id);
              } else {
                return;
              }
          });
          this.deletedAliases = [];
        }
        this.updateLoading = false;
        this.$message({
          message: "Poi updated successfully",
          type: "success",
        });
      },
  
      addAlias() {
        if(this.aliases[0].alias != null) {
        if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
          if(this.aliasToAdd.aliasOrganisation != null) {
            this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true});
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            };
          } else {
            this.aliases.push({ alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true});
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          }
  
        } else this.$message({
          message: "Alias cannot be empty",
          type: "error",
        });
      } else {
        if(this.aliasToAdd.alias != null && this.aliasToAdd.alias != "") {
          if(this.aliasToAdd.aliasOrganisation != null) {
            this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: this.aliasToAdd.aliasOrganisation.name , organisationId: this.aliasToAdd.aliasOrganisation.id, new: true}
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          } else {
            this.aliases[0] = { alias: this.aliasToAdd.alias, aliasType: null, organisationId: null, new: true}
            this.aliasKey++;
            this.aliasToAdd = {
              alias: null,
              aliasOrganisation: null,
            }
          }
        } else this.$message({
          message: "Alias cannot be empty",
          type: "error",
        });
      }
      },
  
      async removeAlias(alias) {
        this.deletedAliases.push(alias);
        
        if(this.aliases.length > 1) {
         let index = this.aliases.findIndex(x => x.alias === alias.alias);
            if (index !== -1) {
              this.aliases.splice(index, 1);
            }
        } else {
          this.aliases = [{alias: null, aliasType: null, organisationId: null}]
        }
      },
  
      selectFbo(item) {
        this.selectedFbo = item;
        this.checkFboCode(item);
        this.fboCard = false;
      },
  
      selectActivityPoint(item) {
        this.selectedAP = item;
        this.activityPointCard = false;
      },
  
      clearPlace() {
        this.place = null;
        (this.customMarker = true), (this.formattedAddress = "");
        this.placeName = "";
        this.friendlyName = ""
        this.country = " ";
        this.address = " ";
        this.coordinates = " ";
        this.chosenLatitude = " ";
        this.chosenLongitude = " ";
        this.postalCode = " ";
      },
  
      addFbo() {
        if (this.fboCard) {
          this.fboCard = false;
        } else this.fboCard = true;
      },
  
      openAlias() {
        if (this.aliasCard) {
          this.aliasCard = false;
        } else this.aliasCard = true;
      },
  
      addActivityPoint() {
        if (this.activityPointCard) {
          this.activityPointCard = false;
        } else this.activityPointCard = true;
      },
  
      filterPois() {
        if (this.filterPoiCard === true) {
          this.filterPoiCard = false;
        } else {
          this.filterPoiCard = true;
        }
      },
  
      async getFboCodes(val) {
        this.fboCodes = await this.$API.getOrgFboCodes(val.id);
      },
  
      // MAP //////////////////////////// MAP ///////////////////////////////////////////////// MAP //////////////////////////////////////// MAP /////////////////////////// MAP //////////////
  
      updateCoordinates(data) {
        this.chosenLatitude = data.latLng.lat();
          this.chosenLatitude = this.chosenLatitude.toFixed(7);
          this.chosenLongitude = data.latLng.lng();
          this.chosenLongitude = this.chosenLongitude.toFixed(7);
      },  
    },
  };
  
  
  </script>
  
  <style scoped>
  /* MapView */
  .mapLocal {
    height: inherit;
    width: 100%;
  }
  
  .mapLocal ::v-deep .vue-map {
      border-radius: 0px 0px 20px 20px;
  }
  .dataSearch {
    width: 15vw;
    margin-top: 1vh;
    margin-bottom: -1vh;
  }
  
  .aliasAddBar {
    width: 18vw;
    margin-top: 1vh;
    margin-bottom: -1vh;
  }  
  .activityPoint {
    width: 15vw;
  }  
  .infoSave {
    position: absolute;
    bottom: 1.5vh;
    right: 1vw;
  }
  </style>
  